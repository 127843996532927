.blogInfo {
  background-color: #f1f1f1;
  min-height: 100%;
  overflow-y: auto;
  .titleDiv {
    height: 60px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    line-height: 60px;
    padding-left: 20px;
    position: fixed;
    text-align: start;
    img {
      height: 30px;
    }
  }
  .content {
    padding: 20px;
    margin: 30px 20%;
    background-color: white;
    margin-bottom: 60px;
    min-height: 650px;
    margin-top: 80px !important;
    .title {
      font-size: 22px;
      font-weight: 700;
      text-align: center;
      padding: 4px;
      // border-bottom: 1px solid rgb(235, 235, 235);
    }
    .info {
      text-align: center;
      padding: 10px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.509);
      border: 1px solid rgb(235, 235, 235);
      .name {
        margin-left: 10px;
      }
    }
    .commentDiv {
      text-align: center;
    }
    .comment:hover {
      color: rgb(255, 157, 0) !important;
    }
    .commentContent {
      width: 80%;
      margin: 10px auto;
      .commentItem {
        text-align: left;
        margin-bottom: 10px;
        line-height: 20px;
        .info {
          font-size: 14px;
          color: black;
          margin-bottom: 6px;
        }
        .time {
          color: gray;
          font-size: 12px;
        }
      }
    }
  }
  .footer {
    // position: absolute;
    // bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  .blogInfo {
    .titleDiv {
      height: 60px;
      width: 100%;
      background-color: white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      line-height: 60px;
      padding-left: 20px;
      // position: fixed;
      img {
        height: 30px;
      }
    }
    .content {
      padding: 20px;
      margin: 30px 5%;
      background-color: white;
      min-height: 550px;
      .commentContent {
        width: 90%;
      }
      .info {
        font-size: 12px;
        border: 1px solid rgb(235, 235, 235);
        .name {
          margin-left: 10px;
        }
      }
    }
  }
}
