.transitPage {
  text-align: center;
  .banner .banner-img {
    width: 100%;
  }
  .banner-img1 {
    display: none;
  }
  .chatIcon {
    width: 300px;
    border: 4px solid #9ee9a0;
    margin: 40px auto 0;
    height: 70px;
    border-radius: 50px;
    line-height: 62px;
    font-size: 26px;
    background-color: #f1f0f1;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      font-size: 40px;
      background-color: #9ee9a0;
      border-radius: 50%;
      color: #f0f0f0;
      text-align: center;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      margin-left: 5px;
    }
  }
  .leave-btn {
    width: 200px;
    height: 50px;
    line-height: 40px;
    border: 1px solid black;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin: 20px auto 0;
    font-size: 20px;
  }
  @media only screen and (max-width: 750px) {
    .banner .banner-img {
      display: none;
    }
    .banner-img1 {
      display: block;
    }
  }
}
