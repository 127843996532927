.header {
  padding: 0px 8.333333%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

  img {
    width: 180px;
    height: 37px;
  }
  .navText {
    font-size: 18px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-left: 30%;
    .nav {
      background-color: #ffd62c !important;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
    }
    .ant-dropdown
      .ant-dropdown-menu
      .ant-dropdown-menu-item-selected
      .ant-dropdown-menu-title-content {
      background-color: #ffd62c !important;
      font-size: 50px;
    }
  }
  .navText div {
    margin-left: 0vw;
    display: block;
    padding: 10px 2vw;
  }
  .mbMenu {
    display: none;

    .nav {
      background-color: #ffd62c;
      opacity: 1;
    }
  }

  @keyframes shake {
    0% {
      opacity: 0;
      transform: translateY(0);
    }

    40% {
      opacity: 0;
      transform: translateY(50);
    }

    50% {
      transform: translateY(-500px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
@media screen and (max-width: 1200px) {
  .header {
    .navText {
      margin-left: 15%;
    }
  }
}
// 手机端
@media screen and (max-width: 800px) {
  .header {
    height: 70px;
    padding: 0px 32px;
    img {
      width: 152px;
      height: 31px;
    }
    .mbMenu {
      display: block;
      font-size: 30px;
      position: relative;
      .navList {
        list-style: none;
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        text-align: center;
        position: absolute;
        right: -32px;
        width: 170px;
        padding: 25px 14px 11px;
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 8px 8px;
        z-index: 100000000000;
      }
      li {
        width: 144px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin: 0 0 14px 0;
        line-height: 40px;
      }
      .yellow {
        background: #ffd62c;
      }
      .hide {
        color: rgba(0, 0, 0, 0.6);
        display: none;
      }
      .show {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .navText {
      display: none;
      margin-left: 10%;
    }
  }
}
