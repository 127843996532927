#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(250, 250, 250, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 2000;
}
