.chat {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 1%;
  z-index: 1000;
  bottom: 20%;
  transform: translate(-50%, -50%);
  animation: shake 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  img {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
  }
}
