.hkPage {
  .topBut {
    position: fixed;
    display: flex;
    height: 30px;
    color: #000;
    z-index: 100;
    width: 100%;
    border-radius: 10px;
    // flex-direction: row-reverse;
    justify-content: center;
    button {
      background-color: #f6f6f6;
      z-index: 1000;
      width: 10vw;
      height: 3vw;
      min-width: 80px;
      min-height: 40px;
      color: #2d2b2b5a;
      font-size: 14px;
    }
    .but1 {
      background-color: #ffde7d;
      // color: white;
      color: #000;
      // border-bottom-left-radius: 10px;
    }
    .but2 {
      // border-bottom-right-radius: 10px;
    }
    button:hover {
      color: #000;
    }
  }
  .service {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .arrow {
      font-size: 60px;
      color: #ffd62c;
    }
  }
  .interact {
    width: 50%;

    .swiperImg {
      width: 100% !important;
      margin: auto !important;
      .talkImg {
        width: 100%;
        display: block;
      }
    }
    .slick-active > button {
      background: #ffd62c !important;
    }
    .ant-carousel .slick-dots li {
      background: #e9e9e9 !important;
    }
  }

  .barrn {
    width: 100%;
    height: auto;
    position: relative;
    color: #ffffff;
    .imgMob {
      display: none;
    }
    .imgPc {
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .nameTitle {
      position: absolute;
      top: 10vw;
      right: 14.0625%;
      font-size: 40px;
      font-weight: 500;
      line-height: 47px;
      .englishTitle {
        font-size: 18px;
        margin-left: 10px;
      }
      .classInfo {
        font-size: 14px;
        line-height: 27px;
        color: #000;
      }
    }
  }

  .courseMol {
    height: 750px;
    color: #ffffff;
    background-image: url(../../../public/assets/HK/courseBg.png);
    background-size: cover;
    display: flex;
    .courseItem {
      width: 16.66666666666667%;
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      padding: 90px 36px 0px;
      .courseLogo {
        width: 100%;
        img {
          display: block;
          width: 140px;
          height: 140px;
          border: #ffffff dashed 1px;
          margin: auto;
        }
      }
      .courseTitle {
        font-size: 20px;
        font-weight: 500;
        margin: 60px 0px 26px;
      }
      .courseInfo {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .dual {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  .chatUs {
    width: 200px;
    height: 60px;
    background: #ffd62c;
    border-radius: 30px 30px 30px 30px;
    font-size: 18px;
    color: #000000;
    line-height: 60px;
    text-align: center;
    margin: auto;
    margin-top: 86px;
  }
  .teachers {
    display: flex;
    align-items: center;
    // margin: 0px 14.6875%;
    justify-content: space-evenly;
    height: auto;
    .teacherItem {
      width: 20.833333333%;
      min-width: 330px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      height: 465px;
      .teacherHead {
        width: 100%;
        background-color: #c4efdc;
        img {
          display: block;
          margin: auto;
          width: 148px;
          padding: 16px 0px;
        }
      }
      .teacherContent {
        padding: 0px 27px;

        .teacherName {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          margin: 36px 0px 10px;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #b6b6b6;
            margin-left: 6px;
          }
        }
        .teacherInfo {
          font-size: 16px;
          color: #858585;
          line-height: 26px;
        }
      }
    }
    .higthBox {
      height: 530px;
    }
  }
  .teacherMob {
    display: none;
  }
  .teacherAptitude {
    margin-top: 90px;
    background-image: url(../../../public/assets/teacherAptitudeBg.png);
    background-size: cover;
    height: auto;
    padding-bottom: 20px;
    .eadline {
      color: #ffffff;
    }
    .aptitudeImg {
      width: 90%;
      margin: auto;
      > img {
        display: block;
        width: 100%;
      }
      .aptitudeMob {
        display: none;
      }
      .aptitudePc {
        display: block;
      }
    }
    .chatUs {
      margin-top: 10px;
    }
  }
  .serviceContent {
    display: flex;
    width: 100%;
    .serviceItem {
      width: 25%;
      height: 28vw;
      display: flex;
      flex-direction: column;
      .right {
        width: 100%;
        height: 50%;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .left {
        width: 100%;
        height: 50%;
        background-color: #fffae8;
        padding: 20px 4%;
        .serviceTitle {
          font-size: 14px;
        }
        .serviceInfo {
          color: #d1cec3;
          font-size: 12px;
          margin-top: 1vw;
          line-height: 16px;
          overflow: hidden;
          overflow-y: scroll;
          height: 80%;
        }
      }
    }
    .service1 {
      flex-direction: column-reverse;
    }
  }
}
@media screen and (max-width: 1500px) {
  .hkPage {
    .courseMol {
      height: auto;
      background-image: url(../../../public/assets/HK/courseBgMob.png);
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 40px 32px 20px;
      .courseItem {
        width: 30%;
        background: rgba(0, 0, 0, 0.3);
        padding: 16px 19px 0px;
        margin-bottom: 20px;
        height: 360px;
        .courseLogo {
          width: 100%;
          img {
            width: 92px;
            height: 92px;
            border: #ffffff dashed 0.5px;
          }
        }
        .courseTitle {
          font-size: 18px;
          margin: 20px 0px 15px;
          text-align: center;
        }
        .courseInfo {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .dual {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .hkPage {
    .courseMol {
      .courseItem {
        width: 46%;
      }
    }
    .teacherAptitude .aptitudeImg {
      .aptitudeMob {
        display: block;
      }
      .aptitudePc {
        display: none;
      }
    }
    .teachers {
      display: flex;
      align-items: center;
      // margin: 0px 14.6875%;
      justify-content: space-evenly;
      .teacherItem {
        width: 30%;
        min-width: 200px;
        height: 465px;
        .teacherHead {
          img {
            width: 120px;
          }
        }
        .teacherContent {
          padding: 0px 20px;
          .teacherName {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin: 26px 0px 10px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #b6b6b6;
              margin-left: 6px;
            }
          }
          .teacherInfo {
            font-size: 14px;
            color: #858585;
            line-height: 26px;
          }
        }
      }
      .higthBox {
        height: 530px;
      }
    }
    .teacherMob {
      display: none;
    }
    .serviceContent {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      margin: 0px 10%;

      .serviceItem {
        width: 100%;
        height: 20vw;
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;
        .right {
          width: 25%;
          height: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .left {
          width: 50%;
          background-color: #fffae8;
          padding: 20px 4%;
          height: 100%;
          .serviceTitle {
            font-size: 14px;
          }
          .serviceInfo {
            color: #d1cec3;
            font-size: 12px;
            margin-top: 1vw;
            line-height: 16px;
            overflow: hidden;
            overflow-y: scroll;
            height: 80%;
          }
        }
      }
      .service1 {
        flex-direction: row-reverse;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .hkPage {
      .courseMol {
        .courseItem {
          height: 430px;
        }
      }
      .teacherAptitude {
        // height: 700px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .hkPage {
      .service .arrow {
        font-size: 40px;
      }
      .interact {
        width: 80%;
        margin: auto;
        .swiperImg {
          width: 100% !important;
          margin: auto !important;
          .talkImg {
            width: 100%;
            display: block;
          }
        }
      }
      .barrn {
        .imgMob {
          display: block;
        }
        .imgPc {
          display: none;
        }
        .nameTitle {
          position: absolute;
          top: 30%;
          left: 32px;
          .englishTitle {
            margin-left: 2px;
          }
        }
      }
      .eadline {
        font-size: 30px;

        padding: 50px 0px;
        .line {
          width: 50px;
          height: 4px;
        }
      }
      .chatUs {
        width: 125px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        margin-top: 40px;
      }
      .courseMol {
        padding: 20px 10px 0px;
        .courseItem {
          width: 48%;

          .courseLogo {
            img {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
      .teachers {
        display: none;
      }
      .teacherMob {
        display: block;
        width: 90%;
        margin: auto;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        .teacherItem {
          width: 100%;
          height: 400px;
          .teacherHead {
            img {
              width: 104px;
              margin: auto;
            }
          }
          .teacherContent {
            padding: 0px 20px;
            .teacherName {
              font-size: 16px;
              font-weight: 500;
              color: #000000;
              margin: 26px 0px 10px;
              span {
                font-size: 12px;
                font-weight: 400;
                color: #b6b6b6;
                margin-left: 6px;
              }
            }
            .teacherInfo {
              font-size: 14px;
              color: #858585;
              line-height: 26px;
            }
          }
        }
        .ant-carousel .slick-dots li {
          background: #e9e9e9 !important;
        }
        .slick-active > button {
          background: #ffd62c !important;
        }
      }
      .teacherAptitude {
        // height: 700px;
        background-image: url(../../../public/assets/HK/teacherAptitudeBgMob.png);
      }
      .chatUs {
        margin-top: 40px;
      }
    }
    .serviceContent {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      margin: 0px 10%;
      .serviceItem {
        height: 22vw;
        .left {
          width: 50%;
          padding: 15px 2%;
        }
        .right {
          width: 28%;
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .hkPage {
    .courseMol {
      .courseItem {
        .courseTitle {
          font-size: 16px;
          margin: 20px 0px 15px;
          text-align: center;
        }
        .courseInfo {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .hkPage {
    .courseMol {
      .courseItem {
        height: 440px;
        .courseTitle {
          font-size: 14px;
          text-align: center;
        }
        .courseInfo {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
    .teacherAptitude {
      // height: 550px;
    }
    .serviceContent {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      margin: 0px 5%;
      .serviceItem {
        height: 32vw;
        .left {
          width: 65%;
          padding: 10px 2%;
        }
        .right {
          width: 30%;
        }
      }
    }
  }
}
/* 一般浏览器的不要太小，容易看不见 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* 外层容器 */
.swiper {
  width: 100%;
  height: 400px;
  padding: 0px 20px;
}

/* 轮播项 */
.swiper .swiper-slide {
  width: 50%;
  height: 100%;

  font-size: 32px;
  font-weight: 500;
  color: #ffffff;

  /* 文字垂直居中 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 色彩来源：https://color.uisdc.com/ */
.swiper .swiper-slide-1 {
  background-color: #425066;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.swiper .swiper-slide-2 {
  background-color: #8d4bbb;
}

.swiper .swiper-slide-3 {
  background-color: #9d2933;
}

@media screen and (max-width: 750px) {
  .hkPage {
    .swiper {
      height: 200px !important;
    }
    .swiper .swiper-slide {
      width: 90%;
      .talkImg {
        width: 80%;
      }
    }
  }
}
