.hkonline {
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: 50%;
  }
  @media (max-width: 750px) {
    img {
      width: 100%;
    }
  }
  .icon_boxF {
    position: fixed;
    top: 25%;
    right: 0;
  }

  .mbs_icon {
    width: 80px;
  }
  .whats_icon {
    width: 70px;
    height: 70px;
  }
}
