.aboutUs {
  .banner {
    width: 100%;
    position: relative;
    .bannerMob {
      display: none;
    }
    img {
      display: block;
      width: 100%;
      min-height: 200px;
    }
    .bannerContent {
      position: absolute;
      color: #fff;
      top: 20%;
      left: 14%;
      .chatCh {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 1vw;
        color: #f6f1f1;
      }
      .chatEn {
        font-size: 16px;
        margin-bottom: 2vw;
      }
      .mail {
        font-size: 16px;
        margin-bottom: 10px;
        color: #f6f1f1;
      }
    }
  }
  .chatType {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 82px 13.54166666666667% 60px;
    .chatTypeItem {
      width: 48%;
      box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 26px 36px;
      margin-bottom: 30px;
      font-size: 12px;
      text-align: left;
      .typeName {
        font-size: 18px;
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: gainsboro;
        margin: 26px 0px;
      }
      .typePhone {
        margin-top: 20px;
      }
      img {
        margin-right: 2px;
      }
    }
  }
  .chatInfo {
    margin: 0px 13.54166666666667% 100px;
    .ant-form {
      max-width: 100% !important;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-between;
      .ant-form-item {
        width: 48%;
        .ant-col {
          text-align: left;
        }
        .ant-col-8 {
          flex: 0 0 25%;
        }
        .ant-col-16 {
          flex: 0 0 75%;
          max-width: 75%;
        }
        .ant-row .ant-btn {
          border-radius: 0px;
          margin-right: 10px;
        }
        #nest-messages_user_name,
        #nest-messages_user_phone,
        #nest-messages_user_email {
          height: 50px;
        }
      }
      .email {
        width: 100%;
        .ant-col-8 {
          flex: 0 0 12%;
        }
        .ant-col-16 {
          flex: 0 0 88%;
          max-width: 88%;
        }
      }
      .reset {
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .chatType {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 82px 5% 100px;

      .chatTypeItem {
        width: 48%;
        padding: 26px 26px;

        .typeName {
          font-size: 16px;
        }
      }
    }
    .chatInfo {
      margin: 82px 5% 80px;
    }
  }
  @media screen and (max-width: 750px) {
    .banner {
      .bannerMob {
        display: block;
      }
      .bannerPc {
        display: none;
      }
      .bannerContent {
        position: absolute;
        color: #fff;
        top: 20%;
        left: 10%;
        .chatCh {
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 1vw;
          color: #f6f1f1;
        }
        .chatEn {
          font-size: 12px;
          margin-bottom: 2vw;
        }
        .mail {
          font-size: 14px;
          margin-bottom: 10px;
          color: #f6f1f1;
        }
      }
    }
    .chatType {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 42px 5% 40px;

      .chatTypeItem {
        width: 100%;
        .line {
          margin: 16px 0px;
        }
      }
    }
    .chatInfo {
      margin: 42px 5% 50px;
      .ant-form {
        max-width: 100% !important;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: end;
        .ant-form-item {
          width: 100%;

          .ant-col-8 {
            flex: 0 0 25%;
          }
          .ant-col-16 {
            flex: 0 0 75%;
            max-width: 75%;
          }
          .ant-row .ant-btn {
            border-radius: 0px;
            margin-right: 10px;
          }
          #nest-messages_user_name,
          #nest-messages_user_phone,
          #nest-messages_user_email {
            height: 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    .chatInfo {
      .ant-form {
        .ant-form-item {
          .ant-col-8 {
            flex: 0 0 40%;
          }
          .ant-col-16 {
            flex: 0 0 60%;
            max-width: 60%;
          }
          .ant-row .ant-btn {
            border-radius: 0px;
            margin-right: 10px;
          }
          #nest-messages_user_name,
          #nest-messages_user_phone,
          #nest-messages_user_email {
            height: 40px;
          }
        }
      }
    }
  }
}
.ant-form-item .ant-form-item-label > label {
  text-wrap: wrap;
}
