body,
html {
  //   position: relative;
}
.modal-box {
  .icon {
    width: 100px;
    img {
      width: 100%;
    }
  }
  .modal-title {
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .modal-btn {
    display: flex;
    justify-content: space-between;
    .yesBtn,
    .okBtn,
    .no {
      width: 100px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      background-color: #b7b6b6;
    }
    .yesBtn,
    .okBtn {
      background-color: #0afaf2;
    }
  }
}
.hk-box {
  text-align: center;

  .chatIcon {
    width: 75px;
    position: fixed;
    right: 1%;
    bottom: 20px;
    z-index: 1000;
  }
  /* 0 */
  .navigation {
    height: auto;
    background-color: #7bbbd832;
    position: fixed;
    right: 10%;
  }
  .fixed-box {
    color: #fff;
    font-size: 2rem;
    margin: 40px auto 0px;
    position: relative;
    .fixed-box-text {
      text-align: center;
      padding: 10px;
      width: 20%;
      margin: 0 40%;
      background-color: #2cb2e7;
    }
  }
  .navigation button {
    margin: 10px;
    border: none;
    height: auto;
    background-color: #2cb2e7;
    color: #fff;
    font-size: 20px;
    /* border-bottom: 3px solid #fff; */
    box-shadow: 0px 0px 6px #fff;
    padding: 5px;
    border-radius: 4px;
  }

  .navigation button:hover {
    box-shadow: 0px 0px 6px #e62230;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  .banner .banner-img {
    width: 100%;
  }

  .banner-img1 {
    display: none;
  }

  /* 2 */
  .class-info-title {
    margin: 100px auto 70px;
    font-size: 32px;
    font-weight: 700;
  }

  .class-info {
    width: 50%;
    margin: 0 25%;
    display: flex;
    flex: 50%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .class-info-item {
    /* width: 48.4%; */
    flex-basis: 48%;
    background-color: #ededed;
    padding: 46px 26px 90px;
    border-radius: 12px;
    box-sizing: border-box;
    margin: 0 1% 20px;
  }

  .class-info-item > img {
    width: 80px;
  }

  .line {
    height: 4px;
    background-color: #cfcdce;
    margin: 0 11.57025%;
  }

  .class-info-item-tilte {
    margin: 40px 0;
    font-size: 18px;
    font-weight: 700;
  }

  .class-text {
    color: #3a3a3a;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

  /* 3 */
  .teacher-info {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .teacher-info-item {
    width: 25.833%;
    border: 1px solid #ededed;
    border-radius: 8px;
    box-shadow: 1px 2px 5px #ededed;
  }

  .teacher1,
  .teacher2 {
    margin-right: 10px;
  }

  .teacher1 > .img-box {
    background-color: #fdeec3;
  }

  .teacher2 > .img-box {
    background-color: #fde0c2;
  }

  .teacher3 > .img-box {
    background-color: #ffbaba;
  }

  .img-box {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .teacher-img {
    width: 100%;
  }

  .teacher-name {
    font-size: 24px;
    padding: 16px 0px;
    font-weight: 600;
  }

  .line-1 {
    width: 80%;
    margin: auto;
    height: 2px;
    background-color: #fdeec3;
  }

  .teacher-title {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .teacher-text {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    text-align: left;
    color: #a1a1a1;
    font-size: 12px;
    padding-bottom: 38px;
  }

  /* 4 */
  .teacher-resources {
    width: 100%;
    background-color: #ededed;
  }

  .teacher-resources-title {
    padding-top: 80px;
    font-size: 24px;
  }

  /* 5 */
  .firm-box {
    width: 100%;
  }

  .firm-info-text {
    width: 48.85417%;
    margin: auto;
    text-align: left;
    font-size: 18px;
    color: #212121;
    margin-bottom: 70px;
  }

  .firm-info {
    width: 59.58333%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .firm-info-item {
      width: 24%;
    }
  }

  .firm-info-titlte {
    height: 76px;
    background-color: #2cb2e7;
    text-align: center;
    font-size: 22px;
    border-radius: 6px;
    padding-top: 17px;
    box-sizing: border-box;
    margin: 20px 0;
  }

  .firm-info-box {
    background-color: #e8f7fe;
    padding-bottom: 70px;
  }

  .firm-info-content {
    height: 290px;
    background-color: #fff;
    padding: 18px 20px;
    border: lpx solid #212121 solid #cfcdce;
    box-shadow: 0px 0px 3px #cfcdce;
    border-radius: 6px;
    text-align: left;
    font-size: 18px;
    color: #212121;
    box-sizing: border-box;
  }

  .map-img {
    width: 51.0417%;
  }

  .teacher-img {
    width: 70%;
  }

  /* 7 */
  .footers {
    width: 51.0417%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  .footer-item {
    text-align: left;
    width: 50%;
    margin-top: 40px;
  }

  .footer-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .footer-p {
    font-size: 12px;
    color: #8c8c8c;
  }

  /* 手机端 */
  @media only screen and (max-width: 1024px) {
    .class-info {
      width: 80%;
      margin: auto;
    }

    .map-img,
    .footers {
      width: 80%;
    }

    .firm-info {
      width: 95%;
    }
  }

  @media only screen and (max-width: 750px) {
    .fixed-box {
      color: #fff;
      font-size: 2rem;
      margin: 40px auto 0px;
      position: relative;
      .fixed-box-text {
        text-align: center;
        padding: 10px;
        width: 100%;
        margin: 0;
        background-color: #2cb2e7;
      }
    }
    .banner {
      margin-top: 50px;
    }

    .banner .banner-img {
      display: none;
    }

    .navigation {
      background-color: #7bbbd832;
      position: fixed;
      z-index: 10000;
      height: 50px;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0px;
      text-align: left;
      box-sizing: border-box;
      height: 50px;
    }

    .navigation button {
      font-size: 14px;
    }

    .banner-img1 {
      display: block;
      width: 100%;
    }

    .class-info-title {
      font-size: 20px;
      margin: 50px auto 30px;
    }

    .class-info-item-tilte {
      margin: 10px auto;
      font-size: 14px;
    }

    .class-text {
      font-size: 12px;
      line-height: 18px;
    }
    .class-info-item {
      flex-basis: 48%;
      font-size: 12px;
      background-color: #ededed;
      padding: 8px;
      border-radius: 12px;
      box-sizing: border-box;
      margin: 0 1% 20px;
    }

    .class-info-item > img {
      width: 40px;
    }

    .class-info {
      width: 90%;
      margin: auto;
    }

    .teacher-info {
      width: 100%;
      flex-direction: column;
    }

    .teacher-info-item {
      width: 90%;
      margin: 0 auto 10px;
    }

    .teacher-img {
      width: 100%;
    }

    .firm-info {
      width: 100%;
      .firm-info-item {
        width: 100%;
      }
    }

    .firm-info-text {
      width: 80%;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .firm-info-titlte {
      height: 40px;
      line-height: 40px;
      padding-top: 0px;
      margin: 10px auto;
      font-size: 14px;
      background-color: #e8f7fe;
      font-weight: 700;
      /* margin: 0px auto; */
    }

    .firm-info-content {
      width: 80%;
      margin: auto;
      height: auto;
      padding-bottom: 10px;
      font-size: 12px;
    }

    .teacher-resources-title {
      font-size: 20px;
      padding-top: 30px;
    }

    .teacher-name {
      font-size: 16px;
    }

    .map-img,
    .footers {
      width: 80%;
    }

    .footers {
      width: 80%;
      flex-direction: column;
    }

    .footer-item {
      margin-top: 10px;
      width: 100%;
    }

    .footer-title {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .hk-box .ifr {
      position: fixed;
      width: 100%;
      height: 80%;
      /* right: 0; */
      bottom: 0;
      max-height: 700px;
      box-shadow: 1px 0px 6px #c5c1c1;
      background-color: hsl(199, 92%, 95%);
      display: none;
    }
    .hk-box .open {
      right: 10px;
      top: 90%;
      width: 50px;
      height: 50px;
    }
  }
  .btn {
    position: fixed;
    right: 20px;
    top: 20%;
  }
  .open {
    display: none;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    animation: mydeamon 1s infinite;
    -webkit-animation: mydeamon 1s infinite;
  }
  @keyframes mydeamon {
    0% {
      transform: scale(1.2);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.9;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
}
