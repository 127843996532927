.blog {
  margin: auto;
  width: 60%;
  margin: 20px 20%;
  background-color: white;
  height: auto;
  .blogItem {
    display: flex;
    border-bottom: 1px dashed gainsboro;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0px 5%;
    margin-bottom: 10px;
    .title {
      width: 70%;
      white-space: nowrap; /* 确保文本在一行内显示 */
      overflow: hidden; /* 超出容器部分隐藏 */
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      text-align: left;
    }
    .time {
      color: rgba(123, 123, 129, 0.986);
      font-size: 14px;
      width: 30%;
      text-align: end;
    }
  }
  .blogItem:hover {
    background-color: aliceblue;
  }
  .ant-pagination {
    width: auto;
    margin: auto;
  }
}
@media screen and (max-width: 750px) {
  .blog {
    margin: auto;
    width: 100%;
    margin: 20px auto;
    padding: 2px;
    .blogItem {
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      padding: 0px 5%;
    }
  }
}
