.topBut {
  position: fixed;
  display: flex;
  height: 30px;
  color: #000;
  z-index: 100;
  width: 100%;
  border-radius: 10px;
  // flex-direction: row-reverse;
  justify-content: center;
  button {
    background-color: #f6f6f6;
    z-index: 1000;
    width: 10vw;
    height: 3vw;
    min-width: 80px;
    min-height: 40px;
    color: #2d2b2b5a;
    font-size: 14px;
  }
  .but1 {
    background-color: #ffde7d;
    // color: white;
    color: #000;
    // border-bottom-left-radius: 10px;
  }
  .but2 {
    // border-bottom-right-radius: 10px;
  }
  button:hover {
    color: #000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }
}
.mathsDiv {
  background-color: #53b4f6;
  width: 100%;
  .mathsHeader {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .aboutImg {
    width: 25%;
    position: relative;
    top: -30px;
    /* left: 0px; */
    z-index: 10000;
    img {
      width: 100%;
    }
  }
  .mathsContent {
    padding: 0 16px;
    position: relative;
    top: -40vw;
    .aboutUs {
      width: 100%;
      height: auto;
      background: linear-gradient(180deg, #d2f0ff 0%, #ffffff 100%);
      border-image: linear-gradient(0deg, #c9e8fb, #79c3f5) 1 1;
      opacity: 0.96;
      border-radius: 10px;
      /* position: relative; */
      .aboutUs_content {
        padding: 20px 20px 40px;
      }
      .aboutTitle {
        text-align: center;
        width: 151px;
        height: 35px;
        background: linear-gradient(90deg, #1c72ff 0%, #38acff 100%);
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 35px;
      }
    }

    .teacherModule {
      .teacherTitle {
        width: 279px;
        height: 45px;
        line-height: 45px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 5px;
        text-align: center;
        margin: auto;
        position: relative;
        top: 22px;
        .big,
        .smile {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
          line-height: 22px;
          z-index: 10000;
          position: relative;
        }

        .smile {
          font-size: 16px;
          font-weight: 400;
        }

        .yellow_line {
          width: 263px;
          height: 9px;
          margin: auto;
          background: #ffbf42;
          position: relative;
          top: -18px;
        }
      }
      .teacherContent {
        width: 100%;
        height: auto;
        background: #e7f1ff;
        border-radius: 10px;
        padding: 42px 16px 0px;
        box-sizing: border-box;
        .teacherName {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 40px;
        }
        .photo {
          width: 64px;
          height: 64px;
          /* border-radius: 50%; */
          /* background: #A69471; */
          /* border: 3px solid #FFFFFF; */
          /* box-shadow: 0px 2px 4px 0px rgba(20, 41, 55, 0.17); */
          z-index: 1000;
        }
        .name {
          width: 148px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          background: #b1d2ff;
          border-radius: 25px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          position: relative;
          left: -40px;
          z-index: 900;
        }

        .teacherInfo {
          width: 100%;
          height: auto;
          background: #ffffff;
          border: 2px solid;
          border-image: linear-gradient(143deg, #fbfcff, #28adff, #e4f4ff) 2 2;
          border-radius: 10px;
          box-sizing: border-box;
          position: relative;
          top: -30px;
          clip-path: inset(0 0 round 5px);
          padding: 52px 22px;
          .infoItem {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
          }
          .drop {
            width: 6px;
            height: 6px;
            background: #006fff;
            border-radius: 50%;
            margin-right: 16px;
          }
        }
      }

      .teacherContent1 {
        width: 100%;
        height: auto;
        background: #e7f1ff;
        border-radius: 10px;
        padding: 20px 16px 34px;
        box-sizing: border-box;
        .teacherContent1_item {
          background-color: #ff7431;
          height: auto;
          background: #ff7431;
          border-radius: 25px;
          margin-top: 20px;
          display: flex;
          .left {
            width: 33%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
            }
          }
          .left2 > img {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
          }
          .right {
            width: 67%;
            height: auto;
            min-height: 210px;
            padding: 19px 20px;
            .rightTitle {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
              line-height: 22px;
              padding-bottom: 15px;
            }

            .rightText {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
            }
          }
        }
      }
      .courseInfo {
        margin-top: 62px;
        width: 100%;
        height: auto;
        background: linear-gradient(257deg, #5c9eff 0%, #a8dfff 100%);
        border: 2px solid;
        border-image: linear-gradient(0deg, #c9e8fb, #79c3f5) 2 2;
        border-radius: 10px;
        clip-path: inset(0 0 round 5px);
        .courseInfoTitle {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          padding: 13px 15px;
        }
        .courseContent {
          box-sizing: border-box;
          width: 100%;
          height: auto;
          background: #ffffff;
          border-radius: 10px;
          padding: 0px 14px 35px;
          .courseTitle {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            padding: 26px 0px;
          }
          .grade {
            width: 100%;
            img {
              width: 100%;
              object-fit: fill;
            }
          }
          .classResearch {
            width: 100%;
            height: auto;
            background: #e7f1ff;
            border-radius: 5px;
            padding: 25px 19px;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
          }
          .classList {
            margin-top: 20px;
            width: 100%;
            height: auto;
            background: #e7f1ff;
            border-radius: 5px;
            padding: 30px 26px 1px 43px;
            .classItem {
              width: 100%;
              height: auto;
              background: #ffffff;
              border: 1px solid #2d466c;
              border-radius: 5px;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              line-height: 25px;
              padding: 20px;
              position: relative;
              margin-bottom: 40px;
              .classIcon {
                width: 43px;
                height: 43px;
                position: absolute;
                top: -10px;
                left: -30px;
              }
              .calssTitle {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #1d78ff;
                line-height: 12px;
                margin-bottom: 22px;
              }
            }
          }

          .advantage {
            width: 100%;
            height: auto;
            background: #ffffff;
            border: 1px dashed #548ae7;
            border-radius: 40px;
            padding: 25px 29px 5px;
            .advantageItem {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #006fff;
              img {
                width: 17px;
                height: 17px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.mathsPc {
  .banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .titleBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 90px auto 48px;
    text-align: center;
    position: relative;
    width: 20%;
    min-width: 214px;
    .icon_left {
      width: 58px;
      height: 58px;
      position: absolute;
      left: 10%;
      top: -40px;
    }
    .title {
      text-align: center;
      font-size: 50px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 16px;
      z-index: 100;
    }
    .icon_right {
      width: 42px;
      height: 42px;
      position: absolute;
      left: 70%;
      top: 0px;
    }
  }
  .subheading {
    font-size: 20px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    color: #000000;
    line-height: 23px;
    margin: 36px auto;
    text-align: center;
  }
  .companyProfile {
    margin: auto;
    text-align: center;
    margin-bottom: 23px;

    .underline {
      margin: auto;
      width: 120px;
      height: 6px;
      background: #ffd62c;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: relative;
      top: -35px;
      z-index: -1;
    }
    .content {
      width: 70%;
      min-width: 300px;
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      -webkit-background-clip: text;
      text-align: center;
      margin: auto;
    }
  }
  .teacher_introduced {
    background-image: url(../../../public/assets/Maths/teacherbag.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* min-height: 900px; */
    height: auto;
    text-align: center;
    min-width: 820px;
    padding-bottom: 6vw;
    .teacher_title {
      font-size: 3vw;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      padding-top: 7vw;
    }
    .nav {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 23px;
      -webkit-background-clip: text;
      margin-top: 28px;
      .teacherNav {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 40px;
          margin-top: 5px;
        }
      }
      .teacherNav1 {
        margin-right: 56px;
      }
      .yellow {
        color: #ffd62c;
      }
    }
    .teacher_box {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      margin-top: 8vw;
      // align-items: flex-end;

      .teacher_item {
        width: 19%;
        max-width: 400px;
        height: 16vw;
        min-height: 350px;
        background: #d7ffd2;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        padding: 0 20px;
        position: relative;
        display: flex;
        justify-content: center;
        img {
          width: 9vw;
          max-width: 200px;
          position: absolute;
          top: -5vw;
        }
        .teacher_name {
          padding-top: 5vw;
          font-size: 1.5vw;
          font-weight: 500;
          color: #000000;
          line-height: 35px;
          margin-bottom: 17px;
        }

        .teacher_info {
          font-size: 15px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 22px;
          padding: 0vw 0 3vw;
        }
      }
      .tow {
        height: 19vw;
        min-height: 380px;
        background: #f3e9da;
        margin: 0 40px;
      }

      .therr {
        background: #d6ebff;
      }
    }
  }
  .company_info {
    text-align: center;
    margin: auto;
    width: 57.395833%;

    .tableImg {
      width: 100%;
      img {
        display: block;
        width: 100%;
      }
    }
    .subheadingContent {
      margin: auto;
      width: 80%;
      height: 95px;
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 28px;
    }

    .grade {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .grade_item {
        width: 31.896%;
        min-width: 280px;
        height: auto;
        background-image: url(../../../public/assets/Maths/grade1.png);
        background-size: 100% 100%;
        padding: 24px 26px 46px;
        /* border: 1px dashed #00000089; */
        margin-bottom: 20px;
        .gradeName {
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 28px;
          margin: 0px auto 49px;
        }
        .gradeContent {
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          line-height: 26px;
        }
      }
      .grade_item2 {
        background-image: url(../../../public/assets/Maths/grade2.png);
      }
      .grade_item3 {
        background-image: url(../../../public/assets/Maths/grade3.png);
      }
      .grade_item4 {
        background-image: url(../../../public/assets/Maths/grade4.png);
      }
      .grade_item5 {
        background-image: url(../../../public/assets/Maths/grade5.png);
      }
      .grade_item6 {
        background-image: url(../../../public/assets/Maths/grade6.png);
      }
    }
  }
  .cloudLeft {
    width: 61.25%;
    background-image: url(../../../public/assets/Maths/cloudbag1.png);
    height: 35vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4vw 0 0 150px;
    display: flex;
    .cloudLefImg {
      width: 40.64%;
      min-width: 200px;
      img {
        width: 100%;
        text-align: center;
      }
    }
    .round {
      width: 50%;
      min-width: 300px;
      img {
        width: 100%;
      }
    }
  }
  .cloudRight {
    width: 100%;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
    height: 35vw;
    .cloudRightBox {
      width: 73.25%;
      height: 100%;
      display: flex;
      background-image: url(../../../public/assets/Maths/cloudbag2.png);
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      .cloudRight_img {
        width: 40.64%;
        img {
          width: 100%;
        }
      }

      ul li {
        width: 30vw;
        min-width: 300px;
        /* height: 80px; */
        background: #ffffff;
        box-shadow: 0px 3px 8px 0px #bed7ff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        display: flex;
        align-items: center;
        margin-bottom: 1vw;
        padding: 1vw 1vw;
        box-sizing: border-box;
        font-size: 20px;
        img {
          display: inline-block;
          width: 3vw;
          height: 3vw;
          margin-right: 12px;
        }
      }
    }
  }
}
.one {
  padding: 25px 13px;
  width: 10%;
}

.classBag,
.classBag > img {
  width: 100%;
}

@media screen and (min-width: 800px) {
  .teacherContent1 {
    .teacherContent1_item {
      .rightText {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .aboutImg {
    width: 20%;
  }
}

.bule {
  color: #3c18ac;
}

@media screen and (max-width: 1500px) {
  .mathsPc {
    .company_info {
      width: 80%;
    }
  }

  ul li {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1200px) {
  .mathsPc {
    .teacher_introduced {
      .teacher_box {
        .teacher_item {
          width: 25%;
        }
      }
    }
  }
}

/* ../../../public/assets/maths */

@media screen and (min-width: 900px) {
  .mathsPc {
    display: block;
  }

  .mathsDiv {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .mathsPc {
    display: none;
  }

  .mathsDiv {
    display: block;
  }
}
