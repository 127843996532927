.footer {
  width: 100%;
  height: 60px;
  background: #000000;
  opacity: 1;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
}
@media screen and (max-width: 800px) {
  .footer {
    font-size: 14px;
    word-wrap: break-word;
  }
}
