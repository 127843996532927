.slick-slide {
  // text-align: center;
  height: auto;
  background: #fff;
  overflow: hidden;
}

.slick-slide h3 {
  color: #fff;
}
.ant-carousel .slick-dots li {
  background: #c1bdbd !important;
}
.slick-active > button {
  background: #ffd62c !important;
}
.rightIcon,
.leftIcon {
  font-size: 60px;
  color: #ffd62c;
  font-weight: 600;
}
.singaporePage {
  .swiper-slide {
    margin-right: 0px;
    margin: auto;
  }
  width: 100%;
  .singaporeHeader {
    width: 100%;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    .name {
      color: #ffffff;
      position: absolute;
      font-size: 30px;
      top: 40%;
      font-weight: 500;
      right: 10%;
    }
  }

  .subheading {
    text-align: center;
    color: #999ca5;
  }
  .typeArea {
    padding: 0px 10%;
  }
  .courseMol {
    width: 100%;
    display: flex;
    margin: 50px 0 0;
    justify-content: space-between;
    .courseImg {
      width: 48%;
      img {
        width: 100%;
      }
    }

    .letf {
      width: 52%;
    }

    .right {
      width: 50%;
      .round {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: #ffd62c;
        border-radius: 50%;
        margin-right: 6px;
      }

      .rightTitle {
        font-size: 30px;
        font-weight: bold;
        color: #252e38;
        line-height: 45px;
      }

      .word1 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
      }

      .word2 {
        font-size: 18px;
        margin-top: 20px;
        color: #999ca5;
      }
    }
  }
  .schoolBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .schoolItem {
      height: 200px;
      width: 41.145833%;
      padding: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 20px 1px rgba(227, 227, 227, 0.45);
      border-radius: 10px;
      margin-bottom: 62px;
      .schoolItemLeft {
        width: 130px;
        height: 130px;
        background: #e9f8ff;
        border-radius: 10px;
        font-size: 60px;
        font-weight: bold;
        color: #4c9ee1;
        line-height: 130px;
        text-align: center;
        margin-right: 10px;
      }
      .schoolItemRight {
        width: 75.2840909%;
        font-size: 16px;
      }
    }
  }

  .smallTitle {
    font-size: 30px;
    font-weight: bold;
    color: #252e38;
    line-height: 45px;
    text-align: center;
    margin: 68px auto 56px;
    .round {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #ffd62c;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .copywriting {
    font-size: 14px;
    color: #999ca5;
    text-align: center;
    margin-bottom: 30px;
  }
  .coursesBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 84px 0px 0px;
    .coursesItem {
      width: 46.4705882%;
      /* width: 41.1458333333%; */
      height: 500px;
      border: 2px solid #ececec;
      border-radius: 10px;
      .coursesImg {
        width: 100%;
      }
      .coursesImg > img {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
      }
      .coursesInfo {
        padding: 33px 35px;
        .coursesTitle {
          font-size: 24px;
          font-weight: bold;
          color: #252e38;
          margin-bottom: 24px;
        }
        .coursesContent {
          font-size: 18px;
          color: #999ca5;
          line-height: 30px;
        }
      }
    }
  }
  .payBox {
    text-align: center;
    display: flex;
    justify-content: space-between;
    .payItem {
      background-color: #fffae5;
      width: 30%;
      padding: 100px 0;
      border-radius: 20px;
      .payTime {
        font-size: 20px;
        font-weight: 600;
      }
      .price {
        font-size: 50px;
        color: #ffd62c;
        font-weight: 800;
        margin: 50px 0 80px;
        span {
          font-size: 20px;
          color: #666666;
          font-weight: 400;
        }
      }
      .chatUs {
        margin: auto;
        width: 60%;
        height: 60px;
        line-height: 60px;
        color: #ffd62c;
        font-size: 18px;
        font-weight: 300;
        border: 1px solid #ffd62c;
        border-radius: 40px;
      }
    }
  }
  .reasonBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .reasonItem {
      width: 29.4117647%;
      background: #89919b;
      box-shadow: 0px 4px 20px 1px rgba(227, 227, 227, 0.45);
      border-radius: 10px;
      position: relative;
      margin-bottom: 30px;
      .reasonImg > img {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
      .reasonWord {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: #ffffff;
        opacity: 0.7;
        border-radius: 0px 0px 10px 10px;
        font-size: 18px;
        font-weight: bold;
        color: #252e38;
        line-height: 100px;
        text-align: center;
      }
    }
  }
  .swiper {
    height: auto;
  }
  .swiperMod {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .parentsModule {
    width: 100%;
    color: #252e38;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    .mob {
      display: none;
    }
    .pc {
      display: block;
    }
    .parentsImg {
      width: 45%;
      img {
        display: block;
        width: 100%;
      }
    }

    .parentsWord {
      width: 55%;
    }
    .studentFeedback {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
    }
    .parentsName {
      font-size: 30px;
      font-weight: bold;
      color: #26305d;
      line-height: 32px;
      margin: 20px 0 17px;
    }

    .years {
      font-size: 16px;
      font-weight: 400;
      color: #b6b6b6;
      line-height: 24px;
    }
  }
  .teacherBox {
    background-color: #fffae5;
    width: 100%;
    height: auto;
    .teachersMob {
      display: none;
    }
    .teachersPc {
      display: block;
    }
    .teachers {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 50px;
      height: auto;
      .teacherItem {
        width: 30%;
        background: #ffffff;
        border: 1px solid #ffd62c;
        border-radius: 20px;
        padding: 26px;
        .line {
          width: 35px;
          height: 5px;
          background: #ffd62c;
        }
        .imgBag {
          width: 100%;
          height: 20vw;
          background-size: 100% 100%;
          border-radius: 20px;
          .teacherContent {
            box-sizing: border-box;
            height: 100%;
            padding: 20px 40px 0;
            font-size: 16px;
            color: #26305d;
            line-height: 24px;
            background: #ffd62c;
            opacity: 0;
            border-radius: 20px;
            overflow-y: auto;
          }
          .teacherContent:hover {
            opacity: 1;
          }
        }
        .teacherName {
          margin-top: 29px;
          font-size: 24px;
          font-weight: bold;
          color: #252e38;
        }

        .teacherLife {
          font-size: 16px;
          color: #666666;
          line-height: 45px;
          margin-left: 19px;
          display: none;
        }
        .teacherWord {
          margin: 17px 0 16px;
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .icon {
          display: flex;
          flex-direction: column;
          .iconBox {
            display: flex;
            align-items: center;
            img {
              height: 15px;
              width: 18px;
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              color: #b6b6b6;
              line-height: 24px;
              margin-right: 24px;
            }
          }
        }
      }
    }
  }
  .flowPath {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
    .arrow {
      font-size: 20px;
      color: #ffd62c;
      font-weight: 600;
    }
    .flowPathItem {
      width: 27%;
      height: 341px;
      background: #fffae5;
      border-radius: 30px;
      text-align: center;
    }

    .stepImg {
      display: inline-block;
      margin: 40px auto 12px;
    }

    .stepTitle {
      font-size: 24px;
      height: 24px;
      font-weight: bold;
      color: #252e38;
      line-height: 45px;
    }

    .stepContent {
      max-width: 80%;
      margin: 0 auto;
      font-size: 16px;
      color: #666666;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 1200px) {
    .typeArea {
      padding: 0px 5%;
    }
    .courseMol {
      .right {
        width: 50%;
        .round {
          width: 12px;
          height: 12px;
        }

        .rightTitle {
          font-size: 20px;
          line-height: 25px;
        }

        .word1 {
          font-size: 16px;
        }

        .word2 {
          font-size: 14px;
        }
      }
    }
    .schoolBox {
      .schoolItem {
        height: 200px;
        width: 48%;
        padding: 10px;
      }
    }
    .coursesBox {
      .coursesItem {
        height: 420px;

        .coursesInfo {
          padding: 33px 30px;
          .coursesTitle {
            font-size: 20px;
            font-weight: bold;
            color: #252e38;
            margin-bottom: 24px;
          }
          .coursesContent {
            font-size: 16px;
            color: #999ca5;
            line-height: 28px;
          }
        }
      }
    }
    .reasonBox {
      .reasonItem {
        width: 45%;
      }
    }
    .teacherBox {
      .teachersMob {
        display: block;
      }
      .teachersPc {
        display: none;
      }
      .teachers {
        .teacherItem {
          width: 40%;

          .imgBag {
            width: 100%;
            height: 280px;
            background-size: 100% 100%;
            border-radius: 20px;
          }
          .teacherName {
            margin-top: 29px;
            font-size: 24px;
            font-weight: bold;
            color: #252e38;
          }

          .teacherLife {
            font-size: 16px;
            color: #666666;
            line-height: 45px;
            margin-left: 19px;
            display: none;
          }
          .teacherWord {
            margin: 17px 0 16px;
            font-size: 16px;
            color: #666666;
            line-height: 24px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .icon {
            display: flex;
            flex-direction: column;
            .iconBox {
              display: flex;
              align-items: center;
              img {
                height: 15px;
                width: 18px;
                margin-right: 8px;
              }
              span {
                font-size: 14px;
                color: #b6b6b6;
                line-height: 24px;
                margin-right: 24px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .typeArea {
      padding: 0px 3%;
    }
    .courseMol {
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        .round {
          width: 12px;
          height: 12px;
        }

        .rightTitle {
          font-size: 20px;
          line-height: 25px;
        }

        .word1 {
          font-size: 16px;
        }

        .word2 {
          font-size: 14px;
        }
      }
    }
    .smallTitle {
      font-size: 18px;
      font-weight: bold;
      color: #252e38;
      line-height: 18px;
      margin: 20px auto 30px;
      .round {
        display: inline-block;
        width: 12px;
        height: 12px;
      }
    }
    .schoolBox {
      .schoolItem {
        height: auto;
        width: 48%;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        .schoolItemLeft {
          width: 100px;
          height: 100px;
          line-height: 100px;
          font-size: 40px;
          margin-bottom: 10px;
        }
        .schoolItemRight {
          width: 100%;
        }
      }
    }
    .coursesBox {
      flex-wrap: wrap;
      margin: 24px 0px 26px;
      .coursesItem {
        height: 420px;
        width: 100%;
        margin-bottom: 20px;
        .coursesInfo {
          padding: 33px 30px;
          .coursesTitle {
            font-size: 20px;
            color: #252e38;
          }
          .coursesContent {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
    .payBox {
      .payItem {
        width: 32%;
        padding: 30px 5px;
        border-radius: 20px;
        .payTime {
          font-size: 18px;
        }
        .price {
          font-size: 20px;
          margin: 20px 0 20px;
          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
        .chatUs {
          height: 40px;
          line-height: 40px;
          font-size: 12px;
          font-weight: 400;
          border-radius: 20px;
        }
      }
    }
    .reasonBox {
      .reasonItem {
        width: 100%;
        .reasonWord {
          height: 60px;
          line-height: 60px;
          font-size: 14px;
        }
      }
    }
    .rightIcon,
    .leftIcon {
      font-size: 30px;
    }
    .parentsModule {
      flex-direction: column;
      .mob {
        display: block;
      }
      .pc {
        display: none;
      }
      .parentsImg {
        width: 100%;
        img {
          width: 60%;
          margin: auto;
        }
      }
      .parentsWord {
        width: 90%;
      }
      .studentFeedback {
        width: 100%;
        line-height: 20px;
        font-size: 12px;
      }
    }
    .teacherBox {
      .teachers {
        .teacherItem {
          width: 60%;
        }
      }
    }
    .flowPath .arrow {
      display: none;
    }
    .flowPath {
      margin-bottom: 50px;

      .flowPathItem {
        width: 32%;
        border-radius: 20px;
      }
      .stepImg {
        margin: 20px auto 12px;
        height: 90px;
      }

      .stepTitle {
        font-size: 18px;
        height: 24px;
        font-weight: bold;
        color: #252e38;
        line-height: 24px;
      }

      .stepContent {
        max-width: 90%;
        font-size: 16px;
        margin-top: 14px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .schoolBox {
      .schoolItem {
        padding: 10px;
        margin-bottom: 20px;
        .schoolItemLeft {
          width: 60px;
          height: 60px;
          line-height: 60px;
          font-size: 30px;
          margin-bottom: 10px;
        }
        .schoolItemRight {
          font-size: 12px;
        }
      }
    }
    .coursesBox {
      .coursesItem {
        height: 420px;

        .coursesInfo {
          padding: 33px 30px;
          .coursesTitle {
            font-size: 20px;
            font-weight: bold;
            color: #252e38;
            margin-bottom: 24px;
          }
          .coursesContent {
            font-size: 16px;
            color: #999ca5;
            line-height: 28px;
          }
        }
      }
    }
    .payBox {
      flex-wrap: wrap;
      justify-content: center;
      .payItem {
        width: 80%;
        margin-bottom: 10px;
      }
    }
    .teacherBox {
      .teachers {
        .teacherItem {
          width: 80%;
          background: #ffffff;
          border: 1px solid #ffd62c;
          border-radius: 20px;
          padding: 10px;
          .line {
            height: 3px;
          }
          .imgBag {
            .teacherContent {
              padding: 10px 10px 0;
              font-size: 12px;
              line-height: 20px;
            }
          }
          .teacherName {
            margin-top: 20px;
            font-size: 20px;
          }

          .teacherLife {
            font-size: 14px;
            color: #666666;
            line-height: 45px;
            margin-left: 19px;
            display: none;
          }
          .teacherWord {
            margin: 17px 0 16px;
            height: 40px;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
          }

          .icon {
            .iconBox {
              img {
                height: 12px;
                width: 15px;
                margin-right: 8px;
              }
              span {
                font-size: 12px;
                color: #b6b6b6;
                line-height: 20px;
                margin-right: 24px;
              }
            }
          }
        }
      }
    }
    .flowPath {
      margin-bottom: 50px;
      flex-direction: column;
      .flowPathItem {
        width: 80%;
        border-radius: 10px;
        height: 220px;
        margin-bottom: 20px;
      }
      .stepImg {
        margin: 20px auto 12px;
      }
      .stepTitle {
        font-size: 14px;
        height: 24px;
        font-weight: bold;
        color: #252e38;
        line-height: 24px;
      }

      .stepContent {
        max-width: 90%;
        font-size: 12px;
        margin-top: 14px;
      }
    }
  }
}

.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  color: #ffd62c !important;
  width: 10px;
  height: 10px;
}
