.headerCom {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
.contentBox {
  // height: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
  padding-top: 100px;
}
@media screen and (max-width: 800px) {
  .contentBox {
    padding-top: 70px;
  }
}
@media screen and (max-width: 750px) {
  .contentBox {
    // height: 100%;
    min-height: calc(100% - 70px);
  }
}
