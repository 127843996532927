.content-text {
  margin: 0;
  padding: 2%;
  background-color: #f9f7f2;
  color: rgb(29, 79, 145);
  h3 {
    font-size: 18px;
    line-height: 40px;
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
    line-height: 36px;
    font-weight: 600;
  }
  p {
    line-height: 26px;
  }
}

.back {
  border: 0;
  background-color: rgb(29, 79, 145);
  height: 50px;
  height: 30px;
  font-size: 14px;
  color: #fff;
}
