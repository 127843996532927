.homePage {
  color: #ffffff;
  height: 100%;
  // position: absolute;
  .pc {
    display: flex;
    height: 100%;
  }
  .mob {
    display: none;
    height: 100%;
  }

  .otherBox:hover,
  .singaporeBox:hover,
  .hongkongBox:hover {
    width: 40%;
    background-color: gray;
  }
  .module {
    width: 33.333333%;
    height: 100%;
    // padding-top: 5vw;
    text-align: center;
    transition: width 1s;
    .moduleitem {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .signLogo {
      width: 100%;
      padding-top: 20px;
      img {
        width: 106px;
        height: 106px;
        display: block;
        margin: auto;
      }
    }
    .countryName {
      font-size: 40px;
      font-weight: bold;
      color: #ffffff;
      margin: 40px auto;
    }
    .line {
      width: 70px;
      height: 8px;
      background: #ffd62c;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: auto;
    }
    .classTitle {
      margin: 54px auto;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
    }
    .classInfo {
      width: 100%;
      padding: 0px 10%;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 1200px) {
    .pc {
    }
    .module {
      .signLogo {
        width: 100%;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .countryName {
        font-size: 30px;
      }
      .line {
        width: 60px;
        height: 7px;
      }
      .classTitle {
        font-size: 20px;
        font-weight: 500;
      }
      .classInfo {
        padding: 0px 5%;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .pc {
      display: none;
    }
    .mob {
      display: block;
      color: #fff;

      .ant-carousel,
      .ant-carousel > .slick-slider,
      .slick-list,
      .slick-track,
      .slick-slide > div {
        height: 100%;
      }
      .slick-active > button {
        background: #ffd62c !important;
      }
    }
    .module {
      min-height: 100%;
      width: 100%;
      .signLogo {
        width: 100%;
        img {
          width: 106px;
          height: 106px;
        }
      }
      .countryName {
        font-size: 40px;
        margin: 3vh auto 3vh;
      }
      .line {
        width: 60px;
        height: 7px;
      }
      .classTitle {
        margin: 5vh auto;
      }
      .classInfo {
        line-height: 24px;
        padding: 0px 10%;
      }
    }
  }
}
