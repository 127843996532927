.youTube {
  width: 100%;

  .youtubePc {
    width: 100%;
    height: 100%;
  }
  .mob {
    display: none;
    width: 100%;
    .youtubeMob {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .mob {
      display: block;
    }
    .pc {
      display: none;
    }
  }
}
