.eadline {
  width: 100%;
  height: auto;
  font-size: 40px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  padding: 82px 0px;
  .line {
    width: 70px;
    height: 8px;
    background: #ffd62c;
    opacity: 1;
    margin: auto;
    margin-top: 20px;
  }
}
@media screen and (max-width: 750px) {
  .eadline {
    font-size: 26px;
    padding: 50px 0px;
    .line {
      width: 50px;
      height: 4px;
      margin-top: 10px;
    }
  }
}
