.singapore {
  width: 100%;

  // img {
  //   width: 100%;
  // }
  .imgDf {
    display: none;
  }
  @media only screen and (max-width: 1000px) {
    .imgDf {
      display: block;
    }
    .imgPc {
      display: none;
    }
  }
  .chatSg {
    width: 75px;
    position: fixed;
    right: 1%;
    bottom: 20px;
    z-index: 1000;
  }
}
